export const TriggerType = Object.freeze({
  IMMEDIATE: 'immediate',
  AFTER_TIME: 'after',
  SPECIFIC_DATE: 'specific',
});

export default {
  getTriggerType: emailTemplate => {
    if (emailTemplate.send_at) {
      return TriggerType.SPECIFIC_DATE;
    }
    if (emailTemplate.days || emailTemplate.hours || emailTemplate.minutes) {
      return TriggerType.AFTER_TIME;
    }
    return TriggerType.IMMEDIATE;
  },
};
