Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const momentToSend = Object.freeze({
  IMMEDIATE: 'immediate',
  AFTER_TIME: 'after_time',
  SPECIFIC_DATE: 'specific_date',
});

const getMomentToSend = emailTemplate => {
  if (emailTemplate.send_at) {
    return momentToSend.SPECIFIC_DATE;
  }
  if (emailTemplate.days || emailTemplate.hours || emailTemplate.minutes) {
    return momentToSend.AFTER_TIME;
  }
  return momentToSend.IMMEDIATE;
};

const toDate = emailTemplate => {
  switch (getMomentToSend(emailTemplate)) {
    case momentToSend.IMMEDIATE:
      return new Date();
    case momentToSend.AFTER_TIME:
      return new Date(new Date().addDays(emailTemplate.days).setHours(emailTemplate.hours, emailTemplate.minutes));
    case momentToSend.SPECIFIC_DATE:
      return new Date(emailTemplate.send_at);
  }
};

const byDate = (templateA, templateB) => toDate(templateA) - toDate(templateB);

/**
 * Returns an email template list sorted by when it is going to be sent, and then sorted by its date
 * @see https://app.asana.com/0/1127832324407932/1158152159664425
 * @param {emailTemplates[]} emailTemplates - List of email templates
 */
export const sortEmailTemplates = emailTemplates => {
  const filterdByImmediate = emailTemplates
    .filter(template => getMomentToSend(template) === momentToSend.IMMEDIATE)
    .sort(byDate);
  const filterdByAfter = emailTemplates
    .filter(template => getMomentToSend(template) === momentToSend.AFTER_TIME)
    .sort(byDate);
  const filterdBySpecific = emailTemplates
    .filter(template => getMomentToSend(template) === momentToSend.SPECIFIC_DATE)
    .sort(byDate);

  return [...filterdByImmediate, ...filterdByAfter, ...filterdBySpecific];
};
