<template>
  <div>
    <b-modal
      :id="id"
      centered
      size="lg"
      data-track="email editor"
      :title="$t(`sparkfunnels.funnels.edit.email_template_modal.title${isEditing ? '' : '_create'}`)"
      title-class="title"
      :noCloseOnBackdrop="false"
      :noCloseOnEsc="true"
      :hide-footer="isLoading"
      footer-class="border-top-0 footer"
      body-class="p-0"
      @hidden="hidden"
    >
      <template>
        <hs-loading v-if="isLoading" />
        <template v-else>
          <div id="container" class="d-flex flex-column">
            <section class="email-section" id="email-content">
              <h2 class="mb-3 text-uppercase font-weight-bold">
                {{ $t('sparkfunnels.funnels.edit.email_template_modal.title_content') }}
              </h2>
              <div class="mb-3">
                <label for="email-template-subject">
                  {{ $t('sparkfunnels.funnels.edit.email_template_modal.subject') }}
                </label>

                <hs-input
                  id="email-template-subject"
                  :text="form.subject ? '' : $t('sparkfunnels.funnels.edit.email_template_modal.clean_subject')"
                  :placeholder="$t('sparkfunnels.funnels.edit.email_template_modal.subject_placeholder')"
                  @blur="$v.form.subject.$touch()"
                  v-model="form.subject"
                  :state="!$v.form.subject.$error ? null : false"
                />
              </div>
              <LeadLinkInstructions />
              <div class="mb-3">
                <label for="email-template-body">{{ $t('sparkfunnels.funnels.edit.email_template_modal.text') }}</label>
                <hs-text-editor
                  id="email-template-body"
                  :initialContent="form.body || ''"
                  :variables="variables"
                  @onUpdate="updatedContent"
                />
              </div>
            </section>
            <hr class="w-100 m-0 tlw-border-2" />
            <section class="email-section" id="email-configuration">
              <h2 class="mb-3 text-uppercase font-weight-bold">
                {{ $t('sparkfunnels.funnels.edit.email_template_modal.title_configuration') }}
              </h2>
              <div class="configuration-trigger-container">
                <EmailTrigger
                  @change="update"
                  :email="editingEmailTemplate"
                  class="w-75"
                  :editing-disabled="!isEditingFields"
                />
                <label for="email-status-input" class="tlw-mt-4">
                  {{ $t('sparkfunnels.funnels.edit.email_template_modal.status_label') }}
                </label>
                <hs-multiselect
                  v-model="form.status"
                  id="email-status-input"
                  class="w-50"
                  track-by="status"
                  label="label"
                  :options="statusOptions"
                  :allow-empty="false"
                  :show-labels="false"
                  :disabled="!isEditingFields"
                  @select="showStatusAlert = true"
                />
                <hs-alert
                  variant="info"
                  :show="showAwaitingStatusInfo"
                  class="mt-1"
                  data-testid="stage-awaiting-status-info"
                  dismissible
                >
                  <small>{{ $t('sparkfunnels.funnels.edit.email_template_modal.awaiting_status_info') }}</small>
                </hs-alert>
                <hs-button
                  @click="toEdit"
                  v-if="!isEditingFields"
                  icon="pencil"
                  variant="outline-primary"
                  data-testid="to-edit-button-modal"
                >
                  Editar
                </hs-button>
              </div>
            </section>
            <hr class="tlw-w-full tlw-mt-8 tlw-mb-0 tlw-border-2" />
            <section class="email-section" id="email-configuration-test">
              <h2 class="tlw-mb-3 text-uppercase font-weight-bold">
                {{ $t('sparkfunnels.funnels.edit.email_template_modal.title_email_test') }}
              </h2>
              <TestEmail
                v-if="funnel && funnel.id"
                :funnel="funnel"
                :emailTemplate="editingEmailTemplate"
                :body="form.body"
                :subject="form.subject"
              />
            </section>
          </div>
        </template>
      </template>
      <template v-slot:modal-footer>
        <section id="footer" class="d-flex justify-content-center align-items-end">
          <hs-button id="cancel-button" variant="link" @click="$bvModal.hide(id)">
            {{ $t('sparkfunnels.funnels.edit.email_template_modal.discard_changes') }}
          </hs-button>
          <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
            <hs-button
              id="save-button"
              class="mb-3 mb-sm-0"
              variant="primary"
              @click="saveOrConfirm"
              :disabled="$v.form.$invalid || !isFutureDate"
            >
              {{ $t('sparkfunnels.funnels.edit.email_template_modal.save_changes') }}
            </hs-button>
          </span>
          <b-tooltip v-if="!$v.$invalid && !isFutureDate" target="disabled-wrapper">
            {{ $t('sparkfunnels.funnels.edit.email_template_modal.disabled_button') }}
          </b-tooltip>
        </section>
      </template>
      <NotifyConfirmModal @save="save" />
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import DatesHelper from '@/shared/helpers/dates';
import { hsLoading } from '@/components';
import EmailTrigger from './EmailTrigger';
import NotifyConfirmModal from './NotifyConfirmModal';
import TestEmail from './TestEmail';
import LeadLinkInstructions from './LeadLinkInstructions';

const STAGE_AWAITING_STATUS = 'awaiting';

export default {
  name: 'FunnelsEditEmailModal',
  components: {
    EmailTrigger,
    hsLoading,
    NotifyConfirmModal,
    TestEmail,
    LeadLinkInstructions,
  },
  props: {
    email: {
      type: Object,
    },
    stage: {
      type: Object,
    },
    funnel: {
      type: Object,
    },
    id: {
      type: String,
      required: true,
    },
    confirmNotify: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('funnel', ['availableStageStatuses']),
    showAwaitingStatusInfo() {
      const { status: formStatus } = this.form.status;
      return formStatus === STAGE_AWAITING_STATUS && this.isEditingFields;
    },
    statusOptions() {
      return this.availableStageStatuses(this.stage).map(status => {
        const type = this.stage.type || this.stage.stageType;
        return {
          label: this.$t(`sparkfunnels.funnels.edit.email_automations.status.${status}.${type}`),
          status,
        };
      });
    },
    isFutureDate() {
      if (this.editingEmailTemplate && this.editingEmailTemplate.send_at) {
        return DatesHelper.isFutureDate(this.editingEmailTemplate.send_at);
      }
      return true;
    },
    isEditing() {
      return this.email ? !!this.email.id : true;
    },
    editedEmailTemplate() {
      return {
        ...this.editingEmailTemplate,
        settings: {
          body: this.form.body,
          subject: this.form.subject,
        },
        status: this.form.status.status,
      };
    },
    variables() {
      const type = this.stage.type || this.stage.stageType;
      const variables = [
        {
          id: 'current_url',
          label: this.$t(`sparkfunnels.email_templates.variables.current_url.${type}`),
        },
        { id: 'next_url', label: this.$t('sparkfunnels.email_templates.variables.next_url') },
        { id: 'lead.name', label: this.$t('sparkfunnels.email_templates.variables.lead_name') },
        { id: 'lead.email', label: this.$t('sparkfunnels.email_templates.variables.lead_email') },
        { id: 'lead.phone', label: this.$t('sparkfunnels.email_templates.variables.lead_phone') },
        { id: 'funnel.name', label: this.$t('sparkfunnels.email_templates.variables.funnel_name') },
        { id: 'stage.name', label: this.$t('sparkfunnels.email_templates.variables.stage_name') },
      ];

      if (this.stage.type === 'Funnel::WebinarStage') {
        variables.push({
          id: 'webinar.title',
          label: this.$t('sparkfunnels.email_templates.variables.webinar_title'),
        });
        variables.push({
          id: 'webinar.start_at',
          label: this.$t('sparkfunnels.email_templates.variables.webinar_start_at'),
        });
      }

      return variables;
    },
  },
  watch: {
    email: function(newEmail) {
      if (newEmail) {
        this.setValues(newEmail);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        body: null,
        subject: null,
        status: {},
      },
      showStatusAlert: false,
      editingEmailTemplate: Object.assign({}, this.email),
      isEditingFields: false,
    };
  },
  validations: {
    form: {
      body: {
        required,
      },
      subject: {
        required,
      },
    },
  },
  methods: {
    toEdit() {
      this.isEditingFields = true;
    },
    updatedContent(event) {
      this.form.body = event.getHTML();
      if (this.form.body === '<p></p>') this.form.body = '';
    },
    update(args) {
      this.editingEmailTemplate = { ...this.editingEmailTemplate, ...args };
    },
    save(alwaysNotifyAll) {
      this.$emit(this.isEditing ? 'edit' : 'create', {
        ...this.editedEmailTemplate,
        always_notify_all: alwaysNotifyAll,
      });

      this.$bvModal.hide(this.id);
    },
    hidden() {
      this.setValues(this.email);
      this.isEditingFields = false;
    },
    setValues(newEmail) {
      this.editingEmailTemplate.id = newEmail.id;
      this.editingEmailTemplate.days = newEmail.days;
      this.editingEmailTemplate.hours = newEmail.hours;
      this.editingEmailTemplate.minutes = newEmail.minutes;
      this.editingEmailTemplate.send_at = newEmail.send_at;
      this.form.body = newEmail.settings.body;
      this.form.subject = newEmail.settings.subject;
      if (this.form.subject.indexOf('funnels.templates.') === 0) {
        this.form.subject = this.$t(`sparkfunnels.${this.form.subject}`);
      }
      if (this.form.body.indexOf('funnels.templates.') === 0) {
        this.form.body = this.$t(`sparkfunnels.${this.form.body}`);
      }

      const tempStatus =
        this.statusOptions.find(statusObj => statusObj.status === newEmail.status) ||
        this.statusOptions[this.statusOptions.length - 1];
      this.form.status = JSON.parse(JSON.stringify(tempStatus));
    },
    saveOrConfirm() {
      if (this.confirmNotify) {
        this.$bvModal.show('notify-confirm-modal');
      } else {
        this.save(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .title {
  font-size: 16px !important;
}

h2 {
  color: $grey-25;
  font-size: 14px;
  letter-spacing: -0.23px;
  line-height: 20px;
}

/deep/ label {
  font-size: 14px;
}

/deep/ small {
  font-size: 12px !important;
}

hr {
  height: 1px;
}

.email-section {
  padding: 32px 25px;
}

/deep/ .footer {
  padding: 0 40px 30px 40px;
}

@media only screen and (max-width: $screen-sm) {
  /deep/ .hs-multiselect {
    width: 100% !important;
  }

  /deep/ input {
    min-height: 45px;
  }

  .email-section {
    padding: 0 20px;
  }

  /deep/ .email-trigger-wrapper {
    width: 100% !important;
  }

  /deep/ .footer {
    padding: 0 20px 40px 20px;

    & > section {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      & button,
      span {
        width: 100%;
      }
    }
  }

  #save-button,
  #cancel-button {
    min-height: 45px;
  }

  /deep/ .multiselect > * {
    min-height: 45px !important;
  }

  /deep/ .multiselect__single {
    display: flex;
    align-items: center;
    min-height: 45px;
  }
}

/deep/ .test-email-container {
  margin-top: 32px;
}

.configuration-trigger-container {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
