<template>
  <div
    class="test-email-container border border-primary rounded-sm d-flex flex-column flex-md-row align-items-center py-4"
  >
    <hs-icon
      variant="light"
      icon="paper-plane"
      class="test-email-icon text-primary align-self-center mx-md-4 align-self-md-start my-1"
    />
    <div class="d-flex flex-column align-items-center align-items-md-start pr-md-4">
      <p class="font-weight-bold text-center mx-3 mx-md-0 text-md-left mb-3">
        {{ $t('sparkfunnels.funnels.edit.email_template_modal.test_email.title') }}
      </p>
      <p
        class="text-center mx-3 mb-4 mx-md-0 text-md-left text-break"
        v-html="
          $t('sparkfunnels.funnels.edit.email_template_modal.test_email.description', {
            email: user.email,
          })
        "
      ></p>
      <hs-loading v-if="isLoading" class="mt-2 mb-4" />
      <template v-else>
        <hs-button
          v-if="sendStatus == null"
          variant="link"
          class="text-primary pl-md-0 mt-2"
          @click="
            () => {
              sendEmail();
              track();
            }
          "
          ><u>{{ $t('sparkfunnels.funnels.edit.email_template_modal.test_email.send') }}</u></hs-button
        >
        <hs-alert
          v-else
          class="status-alert mt-2 d-flex justify-content-center align-items-center w-100"
          :variant="sendStatus === true ? 'primary' : 'danger'"
          show
          dismissible
          @dismissed="sendStatus = null"
        >
          <span
            v-html="
              $t(
                `sparkfunnels.funnels.edit.email_template_modal.test_email.alert.${
                  sendStatus === true ? 'success' : 'error'
                }`
              )
            "
          ></span>
        </hs-alert>
      </template>
      <p class="observation mx-3 text-center text-secondary mx-md-0 text-md-left mb-0">
        {{ $t('sparkfunnels.funnels.edit.email_template_modal.test_email.observation') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService, emailService } from '@/services';
import { hsLoading } from '@/components';

export default {
  components: {
    hsLoading,
  },
  data() {
    return {
      sendStatus: null,
      isLoading: false,
    };
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    emailTemplate: {
      type: Object,
    },
    body: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', {
      user: state => state.loggedUser,
    }),
  },
  methods: {
    sendEmail() {
      this.isLoading = true;
      emailService
        .sendTestEmail({ to: this.user.email, body: this.body, subject: this.subject })
        .then(() => (this.sendStatus = true))
        .catch(() => (this.sendStatus = false))
        .finally(() => (this.isLoading = false));
    },
    track() {
      analyticsService.track({
        event: 'Send Test Email Clicked',
        props: {
          funnel_id: this.funnel.id,
          email_template_id: this.emailTemplate ? this.emailTemplate.id : null,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.test-email-container {
  border-width: 2px !important;
}

.test-email-icon {
  font-size: 1.5rem;
}

.observation {
  font-size: 12px;
}

@media only screen and (min-width: $screen-bs-md) {
  .status-alert {
    width: auto !important;
  }
}
</style>
