

















import Vue from 'vue';

export default Vue.extend({
  name: 'LeadLinkInstructions',
  data() {
    return {
      instructionsText:
        '<strong>Atenção:</strong> Para direcionar o seu Lead para a próxima etapa, digite {{ e selecione "link do próximo estágio". Saiba mais em nosso <a href="https://hero-spark.zendesk.com/hc/pt-br/articles/4408327796109-Como-eu-fa%C3%A7o-para-direcionar-meu-lead-para-outras-etapas-" target="_blank"><u>artigo sobre como direcionar Leads através dos e-mails.</u></a>',
    };
  },
});
