var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-email-container border border-primary rounded-sm d-flex flex-column flex-md-row align-items-center py-4"},[_c('hs-icon',{staticClass:"test-email-icon text-primary align-self-center mx-md-4 align-self-md-start my-1",attrs:{"variant":"light","icon":"paper-plane"}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column align-items-center align-items-md-start pr-md-4"},[_c('p',{staticClass:"font-weight-bold text-center mx-3 mx-md-0 text-md-left mb-3"},[_vm._v("\n      "+_vm._s(_vm.$t('sparkfunnels.funnels.edit.email_template_modal.test_email.title'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"text-center mx-3 mb-4 mx-md-0 text-md-left text-break",domProps:{"innerHTML":_vm._s(
        _vm.$t('sparkfunnels.funnels.edit.email_template_modal.test_email.description', {
          email: _vm.user.email,
        })
      )}}),_vm._v(" "),(_vm.isLoading)?_c('hs-loading',{staticClass:"mt-2 mb-4"}):[(_vm.sendStatus == null)?_c('hs-button',{staticClass:"text-primary pl-md-0 mt-2",attrs:{"variant":"link"},on:{"click":function () {
            _vm.sendEmail();
            _vm.track();
          }}},[_c('u',[_vm._v(_vm._s(_vm.$t('sparkfunnels.funnels.edit.email_template_modal.test_email.send')))])]):_c('hs-alert',{staticClass:"status-alert mt-2 d-flex justify-content-center align-items-center w-100",attrs:{"variant":_vm.sendStatus === true ? 'primary' : 'danger',"show":"","dismissible":""},on:{"dismissed":function($event){_vm.sendStatus = null}}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              ("sparkfunnels.funnels.edit.email_template_modal.test_email.alert." + (_vm.sendStatus === true ? 'success' : 'error'))
            )
          )}})])],_vm._v(" "),_c('p',{staticClass:"observation mx-3 text-center text-secondary mx-md-0 text-md-left mb-0"},[_vm._v("\n      "+_vm._s(_vm.$t('sparkfunnels.funnels.edit.email_template_modal.test_email.observation'))+"\n    ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }