<template>
  <div class="email-trigger-wrapper d-flex flex-column flex-md-row">
    <div class="flex-1 mr-0 mr-md-3">
      <label class="text-nowrap" for="email-template-trigger">{{
        $t('sparkfunnels.funnels.email_trigger.label')
      }}</label>
      <hs-multiselect
        id="email-template-trigger"
        v-model="trigger"
        track-by="label"
        label="label"
        data-testid="email-template-select"
        :options="triggerOptions"
        :searchable="false"
        :allow-empty="false"
        :show-labels="false"
        :disabled="editingDisabled"
      />
    </div>
    <div class="d-flex flex-2">
      <template v-if="trigger.type === 'after'">
        <div class="trigger-input mx-2">
          <label for="days">{{ $t('sparkfunnels.funnels.email_trigger.days') }}</label>
          <hs-input
            id="days"
            data-testid="email-trigger-days"
            type="number"
            v-model="days"
            :disabled="editingDisabled"
          />
        </div>
        <div class="trigger-input mx-2">
          <label for="hours">{{ $t('sparkfunnels.funnels.email_trigger.hours') }}</label>
          <hs-input
            id="hours"
            data-testid="email-trigger-hours"
            type="number"
            v-model="hours"
            :disabled="editingDisabled"
          />
        </div>
        <div class="trigger-input mx-2">
          <label for="minutes">{{ $t('sparkfunnels.funnels.email_trigger.minutes') }}</label>
          <hs-input
            id="minutes"
            data-testid="email-trigger-minutes"
            type="number"
            v-model="minutes"
            :disabled="editingDisabled"
          />
        </div>
      </template>
      <template v-else-if="trigger.type === 'specificDate'">
        <hs-date-time v-model="date" :min="new Date()">
          <label slot="date-label">{{ $t('sparkfunnels.funnels.email_trigger.date') }}</label>
          <label slot="time-label">{{ $t('sparkfunnels.funnels.email_trigger.time') }}</label>
        </hs-date-time>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    HsDateTime: () => import('@/components/DateTime'),
  },
  props: {
    email: {
      type: Object,
    },
    editingDisabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    triggerOptions() {
      return [
        {
          label: this.$t('sparkfunnels.funnels.edit.trigger_option.immediate'),
          type: 'immediate',
        },
        {
          label: this.$t('sparkfunnels.funnels.edit.trigger_option.after'),
          type: 'after',
        },
        {
          label: this.$t('sparkfunnels.funnels.edit.trigger_option.specific_date'),
          type: 'specificDate',
        },
      ];
    },
    days: {
      get: function() {
        return this.email.days;
      },
      set: function(newDays) {
        this.$emit('change', { days: newDays });
      },
    },
    hours: {
      get: function() {
        return this.email.hours;
      },
      set: function(newHours) {
        this.$emit('change', { hours: newHours });
      },
    },
    minutes: {
      get: function() {
        return this.email.minutes;
      },
      set: function(newMinutes) {
        this.$emit('change', { minutes: newMinutes });
      },
    },
    date: {
      get() {
        return this.email.send_at || new Date();
      },
      set(newDate) {
        this.$emit('change', { days: 0, hours: 0, minutes: 0, send_at: newDate });
      },
    },
    trigger: {
      get() {
        if (this.email.send_at) {
          return this.triggerOptions.find(plat => plat.type === 'specificDate');
        }
        if (this.email.days === 0 && this.email.hours === 0 && this.email.minutes === 0) {
          return this.triggerOptions.find(plat => plat.type === 'immediate');
        }
        return this.triggerOptions.find(plat => plat.type === 'after');
      },
      set(newTrigger) {
        switch (newTrigger.type) {
          case 'after': {
            this.$emit('change', { days: 1, hours: 0, minutes: 0, send_at: null });
            break;
          }
          case 'immediate': {
            this.$emit('change', { days: 0, hours: 0, minutes: 0, send_at: null });
            break;
          }
          case 'specificDate': {
            this.$emit('change', { days: 0, hours: 0, minutes: 0, send_at: new Date() });
            break;
          }
          default: {
            break;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input {
  height: 40px;
  border-radius: 2px;

  &[type='number'] {
    width: 50px;
  }
}

@for $i from 1 through 2 {
  .flex-#{$i} {
    flex: $i;
  }
}

@media only screen and (max-width: $screen-bs-sm) {
  /deep/ .hs-date-timer {
    > input {
      min-height: 45px !important;
    }

    &::before {
      line-height: 45px;
    }
  }

  .trigger-input {
    margin: 1rem 0 0 0 !important;

    & + .trigger-input {
      margin-left: 1rem !important;
    }
  }

  /deep/ input {
    &[type='number'] {
      min-height: 45px;
    }
  }

  /deep/ .multiselect > * {
    min-height: 45px !important;
  }

  /deep/ .multiselect__single {
    display: flex;
    align-items: center;
    min-height: 45px;
  }
}
</style>
