import EmailTemplateHelper, { TriggerType } from '@/sparkfunnels/helpers/emailTemplate';
import { format } from '@/shared/helpers/dates';
import funnelApi from '@/sparkfunnels/services/funnel';
import { sortEmailTemplates } from '@/sparkfunnels/helpers/sort';
import dayjs from 'dayjs';
import ToastHelper from '@/shared/helpers/toast';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('funnel', ['getStagesEmails']),
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
  },
  methods: {
    ...mapActions('funnel', ['cleanStagesEmailsContent', 'stagesEmailsContent']),
    getMomentToSendText(emailTemplate, stageType) {
      const trigger = EmailTemplateHelper.getTriggerType(emailTemplate);
      if (trigger === TriggerType.IMMEDIATE) {
        return (
          this.$t(`${this.prefix}.trigger.immediate`) +
          this.$t(`${this.prefix}.status.${emailTemplate.status}.${stageType}`)
        );
      } else if (trigger === TriggerType.SPECIFIC_DATE) {
        const date = format(emailTemplate.send_at, `${this.$t('date.format')} HH:mm`);
        return this.$t(`${this.prefix}.trigger.specific`, { date });
      } else {
        return (
          this.$t(`${this.prefix}.trigger.after`, {
            days: emailTemplate.days,
            hours: emailTemplate.hours,
            minutes: emailTemplate.minutes,
          }) + this.$t(`${this.prefix}.status.${emailTemplate.status}.${stageType}`)
        );
      }
    },
    refreshStagesEmailContent: async function() {
      await this.cleanStagesEmailsContent();
      const { stages } = this.loadedFunnel;

      stages.forEach(stage => {
        this.updateStagesEmails(stage);
      });
    },
    mountStagesEmailContent: async function(stages = []) {
      await this.cleanStagesEmailsContent();

      stages.forEach(stage => {
        this.updateStagesEmails(stage);
      });
    },
    updateStagesEmails(stage) {
      funnelApi
        .getEmailTemplates(stage.funnel_id, stage.id, 1, 50)
        .then(({ data }) => {
          const emails = sortEmailTemplates(
            data.email_templates.map(email => ({
              ...email,
              send_at: email.send_at ? dayjs(email.send_at).toDate() : null,
            }))
          );
          if (emails.length > 0) {
            this.stagesEmailsContent({ id: stage.id, emails });
          }
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.action.fetch.error`)));
    },
    statusStagesEmails(stageId) {
      const stagesEmails = this.getStagesEmails.find(stage => stage.id === stageId);
      if (stagesEmails) {
        return this.checkStatus(stagesEmails.emails);
      }
      return false;
    },
    checkStatus(emails) {
      return emails.some(email => !email.edited);
    },
  },
};
